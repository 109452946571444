import React, { useState, useEffect } from 'react'

import authorJSON from 'src/assets/data/author.json'
// componets
import Layout from 'src/components/Layout'
import Columnists from 'src/components/Columnists'
import PageTitle from 'src/components/PageTitle'

import pageContext from './pageContext.json'

type author = {
  id: string
  slug: string
  name: string
  office: string
  investorCertificate?: string | null
  image: {
    title?: string
    url: string
    alt?: string
  }
  description: string

  preSlug?: string | null
  articleId?: string | null
}

const DeInvestidorparaInvestidor = () => {
  const [author, setAuthor] = useState<author[]>([])

  const breadcrumb = {
    path: [
      { link: '/', text: 'Home' },
      { link: '/de-investidor-para-investidor', text: 'De Investidor para Investidor' },
    ],
  }

  useEffect(() => {
    const newAuthor = authorJSON.map((item: author) => ({
      ...item,
      preSlug: null,
      articleId: null,
    }))

    setAuthor(newAuthor)
  }, [])

  return (
    <Layout pageContext={pageContext}>
      <div className="container">
        <div className="row">
          <PageTitle breadcrumb={breadcrumb} pageTitle={pageContext.pageTitle} />
        </div>
        <hr />
        <div className="row justify-content-center mt-3 mb-5">
          <div className="col-12 col-lg-8">
            {author.map((item) => (
              <div key={item.id} className="my-1 my-md-5">
                <Columnists data={item} componentStyle={{ type: 'culumnList' }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DeInvestidorparaInvestidor
